import React from "react";
import { IconElement } from "../icon.element";

export function SubHeadingList({ features }) {
  if (!Array.isArray(features)) {
    return null;
  }

  return (
    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
      {features.map(({ icon, text }) => (
        <div
          className="mt-2 flex items-center text-md text-gray-600"
          key={text}
        >
          {icon && (
            <span
              className="flex-shrink-0 mr-2 text-gray-400 text-lg"
              aria-hidden="true"
            >
              <IconElement icon={icon} />
            </span>
          )}
          {text && <span>{text}</span>}
        </div>
      ))}
    </div>
  );
}
