import React, { Fragment } from "react";
import { graphql } from "gatsby";
import LayoutGeneral from "../components/layout.general";
import { MdxRendererLocal } from "../components/mdx/mdx-renderer-local.component";
import { CourseModulePostNavigation } from "../components/elements/courses/course-module-post-navigation.component";
import { BreadcrumbsElement } from "../components/elements/navbar/breadcrumbs.element";
import { SubHeadingList } from "../components/elements/heading/sub-heading-list.component";
import { IconElement } from "../components/elements/icon.element";
import Img from "gatsby-image";
import { CourseModuleToc } from "../components/elements/courses/course-module-toc.component";

function CourseOverviewSection({ title, description, items, icon, color }) {
  return (
    <div className="py-6 border-t">
      {title && (
        <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
          {title}
        </h3>
      )}
      {description && (
        <p className="mt-3 max-w-2xl text-sm text-gray-600 sm:mt-4">
          {description}
        </p>
      )}
      <ul className="mt-6 space-y-4">
        {Array.isArray(items) &&
          items.map(item => (
            <li
              key={item?.text || item}
              className="flex space-x-3 items-center"
            >
              {item?.url && item?.text && (
                <a
                  href={item.url}
                  className="text-sm flex text-gray-700 border-b border-dotted hover:border-gray-500"
                  data-tracking-action-label="CTA.DOWNLOAD"
                  data-tracking-action-value={item.text}
                  data-tracking-action-position="Course Resource"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span
                    className={`flex-shrink-0 mr-2.5 ${
                      color || "text-gray-500"
                    }`}
                  >
                    <IconElement icon={icon} />
                  </span>
                  <span>{item.text}</span>
                </a>
              )}
              {typeof item === "string" && (
                <Fragment>
                  <span className={`flex-shrink-0 ${color || "text-gray-500"}`}>
                    <IconElement icon={icon} />
                  </span>
                  <span className="text-sm text-gray-700">{item}</span>
                </Fragment>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
}

function CourseOverviewExpert({ expert }) {
  const { portrait, name, role, contact } = expert || {};

  if (!(portrait && name && role)) {
    return null;
  }

  return (
    <div className="py-6 border-t">
      <div className="flex items-center space-x-4 lg:space-x-6">
        <Img
          fluid={portrait?.childImageSharp?.fluid}
          alt={"Portrait: " + name}
          className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
        />
        {/* <img className="w-16 h-16 rounded-md lg:w-20 lg:h-20" src={image} alt="" /> */}
        <div className="font-medium text-lg leading-6 space-y-1">
          <h3>{name}</h3>
          <p className="text-sm text-gray-700">{role}</p>
          <ul className="flex space-x-5">
            {/* <li>
                     <a href={twitterUrl} className="text-gray-400 hover:text-gray-500">
                         <span className="sr-only">Twitter</span>
                         <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                             <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                         </svg>
                     </a>
                 </li> */}
            {contact?.linkedin && (
              <li>
                <a
                  href={contact.linkedin}
                  data-tracking-action-label="OUTBOUND.SOCIAL"
                  data-tracking-action-value={contact.linkedin}
                  data-tracking-action-position="Course Expert Social"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">LinkedIn</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

function CourseOverview({ learning, resources, expert }) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:py-6 sm:px-6 lg:px-8">
      <div className="sm:grid sm:grid-flow-col sm:grid-cols-3 gap-4 items-start">
        <CourseOverviewExpert expert={expert} />
        <CourseOverviewSection
          title={learning?.title}
          items={learning?.promises}
          icon="lightbulbOn"
          color="text-yellow-500"
        />
        <CourseOverviewSection
          title={resources?.title}
          items={resources?.links}
          icon="fileDownload"
          color="text-gray-500"
        />
      </div>
    </div>
  );
}

function CourseTitle({
  title,
  subtitle,
  lead,
  breadcrumbs,
  currentPath,
  features,
}) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto pt-16 px-4 sm:pt-24 pb-6 sm:px-6 lg:px-8">
        <div className="">
          <div className="mb-4">
            <BreadcrumbsElement items={breadcrumbs} currentUrl={currentPath} />
          </div>

          {subtitle && (
            <strong className="text-base font-semibold text-pink-600 tracking-wide uppercase">
              {subtitle}
            </strong>
          )}

          {title && (
            <h1 className="mt-1 text-3xl font-bold font-sansx text-gray-900 sm:text-4xl sm:tracking-tight lg:text-5xl max-w-5xl">
              {title}
            </h1>
          )}

          {lead && (
            <p className="max-w-2xl mt-5 text-xl text-gray-800">{lead}</p>
          )}

          {Array.isArray(features) && (
            <div className="my-4">
              <SubHeadingList features={features} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ArticleContent({ content, toc = null }) {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        {toc && (
          <div className="md:float-left md:max-w-sm md:mr-12 md:mb-12 md:mt-0 z-10 relative rounded-lg overflow-hidden">
            {toc}
          </div>
        )}

        {content && (
          <div className="mt-6">
            <div className="prose xprose-pink prose-lg text-gray-700 mx-auto">
              <MdxRendererLocal>{content}</MdxRendererLocal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default function CourseModuleTemplate({
  page,
  pageContext,
  data,
  location,
}) {
  const {
    mdx,
    navigationDe,
    navigationEn,
    footerDe,
    footerEn,
    social,
    courseModules,
    courseIndex,
  } = data || {}; // data.mdx holds your post data

  const { language } = pageContext;
  const footer = language.toLowerCase() === "de" ? footerDe : footerEn;
  const navigation =
    language.toLowerCase() === "de" ? navigationDe : navigationEn;

  if (!mdx) {
    return null;
  }

  const {
    fields: { slug },
    frontmatter: {
      title,
      headline,
      description,
      descriptionFeatures,
      learningPromises,
      resources,
      expert,
    },
    body,
  } = mdx;

  const seo = {
    title,
    description,
  };

  const enrichedContext = {
    seo,
    ...pageContext,
  };

  const crumbles = [
    {
      title: courseIndex?.frontmatter?.title,
      url: courseIndex?.fields?.slug,
    },
  ];

  return (
    <LayoutGeneral
      pageContext={enrichedContext}
      path={location?.pathname}
      navigation={navigation}
      footer={footer}
      social={social}
    >
      <CourseTitle
        title={headline}
        lead={description}
        breadcrumbs={crumbles}
        currentPath={slug}
        features={descriptionFeatures}
      />

      <CourseOverview
        learning={learningPromises}
        resources={resources}
        expert={expert}
      />

      <ArticleContent
        content={body}
        toc={<CourseModuleToc embedded={true} node={mdx} />}
      />

      <CourseModulePostNavigation modules={courseModules} currentModule={mdx} />
    </LayoutGeneral>
  );
}

export const pageQuery = graphql`
  query($slug: String!, $language: String!, $courseKey: String!) {
    navigationDe: fragmentsYaml(key: { eq: "navigation-reduced" }) {
      key
      id
      data
    }
    footerDe: fragmentsYaml(key: { eq: "footer-navigation" }) {
      key
      id
      data
    }
    navigationEn: fragmentsYaml(key: { eq: "navigation-reduced-en" }) {
      key
      id
      data
    }
    footerEn: fragmentsYaml(key: { eq: "footer-navigation-en" }) {
      key
      id
      data
    }
    social: fragmentsYaml(key: { eq: "social-profiles" }) {
      key
      id
      data
    }
    mdx: mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      tableOfContents
      frontmatter {
        date
        slug
        title
        headline
        description
        topic
        courseKey
        order
        public
        duration
        type
        descriptionFeatures {
          icon
          text
        }
        learningPromises {
          title
          promises
        }
        resources {
          title
          links {
            text
            url
          }
        }
        expert {
          name
          role
          contact {
            linkedin
          }
          portrait {
            publicURL
            childImageSharp {
              id
              fluid(maxWidth: 250) {
                base64
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
              fixed(width: 250) {
                base64
                aspectRatio
                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
    courseIndex: mdx(
      frontmatter: {
        courseKey: { eq: $courseKey }
        type: { eq: "course-index" }
      }
    ) {
      fields {
        slug
      }
      frontmatter {
        date
        slug
        title
        headline
        description
        topic
        courseKey
        order
        public
        duration
        type
      }
    }
    courseModules: allMdx(
      filter: {
        frontmatter: {
          draft: { ne: true }
          type: { eq: "course-module" }
          language: { eq: $language }
          courseKey: { eq: $courseKey }
        }
        fields: { slug: { ne: $slug } }
      }
      sort: {
        fields: [frontmatter___order, frontmatter___date]
        order: [ASC, DESC]
      }
      limit: 6
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          tableOfContents
          frontmatter {
            headline
            description
            topic
            courseKey
            order
            public
            duration
            type
            date(formatString: "DD.MM.YYYY")
            lead_image {
              publicURL
              childImageSharp {
                id
                fluid(maxWidth: 1080) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                  srcWebp
                  srcSetWebp
                }
                fixed(width: 1080) {
                  base64
                  aspectRatio
                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
            hero {
              theme
              background_color
              accent_color
              foreground_image {
                publicURL
                childImageSharp {
                  id
                  fluid(maxWidth: 1080) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    srcWebp
                    srcSetWebp
                  }
                  fixed(width: 1080) {
                    base64
                    aspectRatio
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
