import React from "react";
import { IconElement } from "../icon.element";

export function CourseModulePostNavigation({ modules, currentModule }) {
  const currentOrder = currentModule?.frontmatter?.order;
  const { node: previousModule } =
    modules?.edges?.find(
      ({
        node: {
          frontmatter: { order },
        },
      }) => order === currentOrder - 1
    ) || {};
  const { node: nextModule } =
    modules?.edges?.find(
      ({
        node: {
          frontmatter: { order },
        },
      }) => order === currentOrder + 1
    ) || {};

  return (
    <nav className="mx-auto max-w-screen-xl border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 ">
      <div className="-mt-px w-0 flex-1 flex">
        {previousModule && previousModule?.frontmatter?.public && (
          <a
            href={previousModule?.fields?.slug}
            className="border-t-2 border-transparent pt-4 pr-1 pl-4 inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-700 hover:border-gray-300"
          >
            <IconElement icon="arrowLeft" className="text-gray-400" />{" "}
            <span className="mx-2 leading-loose">
              {previousModule?.frontmatter?.headline}
            </span>
          </a>
        )}
        {previousModule && !previousModule?.frontmatter?.public && (
          <span className="border-t-2 border-transparent pt-4 pl-4 pr-1 inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-700 hover:border-gray-300">
            <IconElement icon="arrowLeft" className="text-gray-400" />{" "}
            <IconElement icon="lock" />{" "}
            <span className="mx-2 leading-loose">
              {previousModule?.frontmatter?.headline}
            </span>
          </span>
        )}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        {nextModule && nextModule?.frontmatter?.public && (
          <a
            href={nextModule?.fields?.slug}
            className="border-t-2 border-transparent pt-4 pl-1 pr-4 inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-700 hover:border-gray-300"
          >
            <span className="mx-2 leading-loose">
              {nextModule?.frontmatter?.headline}
            </span>{" "}
            <IconElement icon="arrowRight" />
          </a>
        )}

        {nextModule && !nextModule?.frontmatter?.public && (
          <span className="border-t-2 border-transparent pt-4 pl-1 pr-4 inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-700 hover:border-gray-300">
            <IconElement icon="lock" />{" "}
            <span className="mx-2 leading-loose">
              {nextModule?.frontmatter?.headline}
            </span>{" "}
            <IconElement icon="arrowRight" className="text-gray-400" />
          </span>
        )}
      </div>
    </nav>
  );
}
