import React from "react";

export function BreadcrumbsElement({ items, currentUrl }) {
  if (!Array.isArray(items)) {
    return null;
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2">
        {items.map((page, idx) => (
          <li key={page.title}>
            <div className="flex items-center">
              {idx > 0 && (
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
              {page.url && (
                <a
                  href={page.url}
                  className={`${
                    idx > 0 ? "ml-2" : ""
                  } text-sm font-medium text-gray-600 hover:text-gray-700`}
                  data-tracking-action-label={`NAVIGATION`}
                  data-tracking-action-value={`${page.title} | ${page.url}`}
                  data-tracking-action-position={`BREADCRUMB (idx: ${idx})`}
                  aria-current={page.url === currentUrl ? "page" : undefined}
                >
                  {page.title}
                </a>
              )}
              {!page.url && (
                <span
                  className={`${
                    idx > 0 ? "ml-2" : ""
                  } text-sm font-medium text-gray-600 hover:text-gray-700`}
                >
                  {page.title}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
